<template>
  <v-footer padless color="primary" dark>
    <v-col class="text-start" cols="12">
      © 2018 - {{ new Date().getFullYear() }} Global Psytech Sdn. Bhd.
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>